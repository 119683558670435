// import classNames from "classnames";
// import cls from "./ProductCard.module.scss"
import {cartAddItem} from "../../../store/cart";
import {Dispatch} from "react";
import {IProduct} from "../../../types/homePageTypes";
import {ICurrencies} from "../../ForCWV/Wrappers/MainLayout";

// export const containerClasses = (layout?: string) => classNames(cls["product-card"],
//     layout === "grid-sm" && cls["product-card--layout--grid product-card--size--sm"],
//     layout === "grid-nl" && cls["product-card--layout--grid product-card--size--nl"],
//     layout === "grid-lg" && cls["product-card--layout--grid product-card--size--lg"],
//     layout === "list" && cls["product-card--layout--list"],
//     layout === "horizontal" && cls["product-card--layout--horizontal"],
// );
export const containerClasses = (layout?: string) => "";

export const addToCartEventPixel = (product: IProduct, quantity: number, selectedRate: ICurrencies) => {
    if (window.fbq) {
        window.fbq('track', 'AddToCart', {
            product_name: product.flats[0].name,
            product_sku: product.sku,
            product_id: product.flats[0].id,
            product_type: product.type,
            product_price: product.flats[0].price,
            product_quantity: quantity,
            currency: selectedRate.code
        });
    }
};

export const handleAddCardClickWithSellProducts:
    (
        openUpCrossProd: () => void,
        dispatch: Dispatch<any>,
        data: {
            product: IProduct,
            quantity: number,
            cartToken: { cartToken: string },
            customer: { token: string },
            selectedLocale: string,
            selectedCurrCode: string,
            optionsObject: null,
            addToast: (error?: boolean) => void
        },
        routerPush: (url: string) => Promise<boolean>,
        url_key: string,
    ) => Record<string, Record<string, Record<string, () => void>>> =
    (
        openUpCrossProd,
        dispatch,
        data,
        routerPush,
        url_key
    ) => {
        const {
            product,
            quantity,
            cartToken,
            customer,
            selectedLocale,
            selectedCurrCode,
            optionsObject,
            addToast
        } = data;
        const addItemCallback = () => cartAddItem(
            product,
            quantity,
            cartToken,
            customer as { token: string },
            selectedLocale,
            selectedCurrCode,
            optionsObject,
            addToast
        )



    return {
        "true": {
            "true": {
                "true": () => {
                    openUpCrossProd()
                },
                "false": () => {
                    openUpCrossProd()
                }
            },
            "false": {
                "true": () => {
                    openUpCrossProd();
                    addToCartEventPixel(product, quantity, { code: selectedCurrCode } as ICurrencies);
                    dispatch(addItemCallback());
                },
                "false": () => {
                    dispatch(addItemCallback());
                    addToCartEventPixel(product, quantity, { code: selectedCurrCode } as ICurrencies);
                }
            }
        },
        "false": {
            "true": {
                "true": async () => {
                    await routerPush(`/${url_key}`)
                },
                "false": async () => {
                    await routerPush(`/${url_key}`)
                }
            },
            "false": {
                "true": async () => {
                    await routerPush(`/${url_key}`)
                },
                "false": async () => {
                    await routerPush(`/${url_key}`)
                }
            }
        }
    };
}
